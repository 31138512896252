import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { isValidHttpUrl } from "../../utils"
import * as styles from "./mapLinks.module.scss"

const MapLinks = ({ items = [] }) => {
  return (
    <section className={styles.section}>
      <Container>
        <h1 className={`main-banner-heading-55 ${styles.mainHeading}`}>
          Sitemap
        </h1>
        <Row>
          {items?.map((item, ind) => (
            <Col md={4} key={`main_${ind}`}>
              <Row>
                <Col xs={12}>
                  {item?.url ? (
                    <h3 className={styles.mainLink}>
                      {isValidHttpUrl(item?.url) ? (
                        <a href={item?.url}>{item?.title}</a>
                      ) : (
                        <Link to={`/${item?.url}/`}>{item?.title}</Link>
                      )}
                    </h3>
                  ) : (
                    <h3 className={styles.mainLink}>{item?.title}</h3>
                  )}
                </Col>
                {item?.sub_menu?.map((subItem, ind) => (
                  <Col xs={12} key={`sub_${ind}`}>
                    <p>
                      {isValidHttpUrl(subItem?.url) ? (
                        <a href={subItem?.url}>{subItem?.title}</a>
                      ) : (
                        <Link
                          to={
                            (item?.title).toLowerCase() === "portfolio"
                              ? `/portfolio/${subItem?.url}/`
                              : `/${subItem?.url}/`
                          }
                        >
                          {subItem?.title}
                        </Link>
                      )}
                    </p>
                  </Col>
                ))}
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default MapLinks
