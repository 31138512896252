import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SEORevamp from "../components/common/SEO_Revamp"
import MapLinks from "../components/sitemap/MapLinks"
import MainLayout from "../layouts/MainLayout"

const SiteMap = () => {
  const data = useStaticQuery(graphql`
    query getSiteMapItems {
      allStrapiSitemap {
        nodes {
          menus {
            title
            type
            url
            sub_menu {
              title
              url
            }
          }
        }
      }
    }
  `)

  const { menus } = data?.allStrapiSitemap?.nodes[0]

  return (
    <MainLayout bgChanged={false}>
      <MapLinks items={menus} />
    </MainLayout>
  )
}

export default SiteMap

export const Head = () => {
  return (
    <SEORevamp
      title="Sitemap for InvoZone"
      description="With this sitemap, you can reach desired section on InvoZone's website in seconds with a few clicks."
    />
  )
}
